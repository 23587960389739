<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-cell-group inset >
            <van-form>
                <van-field name="notice_wx" label="微信通知" input-align="right">
                    <template #input>
                        <van-switch v-if="wx" v-model="notice.notice_wx" size="20" />
                        <span v-else style="color:red;">您未绑定微信，无法设置</span>
                    </template>
                </van-field>
                <van-field name="notice_email" label="邮件通知" input-align="right">
                    <template #input>
                        <van-switch v-if="email" v-model="notice.notice_email" size="20" />
                        <span v-else style="color:red;">您未绑定邮箱，无法设置</span>
                    </template>
                </van-field>
                <div class="sub_button">
                    <van-button round block type="primary" @click="submit">保存设置</van-button>
                </div>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { edit_notice_request,my_information_request } from '@/network/user'

export default {
    name:'UserNotice',
    data(){
        return {
            wx: 'wx',
            email: 'email',
            notice:{
                notice_wx: false,
                notice_email: false
            },
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
                fixed: false,   //固定
                placeholder: false  //等高占位
            },
            my_nav_bar_name: '通知设置',
        }
    },
    computed:{},
    methods:{
        get_my_information() {
            this.$store.commit('true_loading')
            my_information_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.wx = s.result.wx_id
                        this.email = s.result.email
                        this.notice.notice_wx = s.result.notice_wx
                        this.notice.notice_email = s.result.notice_email
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        submit() {
            this.$store.commit('true_loading')
            edit_notice_request(this.notice)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('保存成功!')
                        this.$router.go(-1);//返回上一层
                    } else {
                        console.log(err)
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_my_information()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>